import React, { useState } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Autosuggest from 'react-autosuggest';
import { ApiRequest } from "gatsby-theme-starberry-lomondgroup/src/common/utils/api_request_utils";
import _ from "lodash"
// import "./postcodeField.scss";

const PostcodeField = (props) => {

  const [suggestions, setSuggestions] = useState([]);

  const [location, setLocation] = useState('');

  const renderSuggestion = suggestion => {

    var address = resultFormation(suggestion);
    return (
      <div className="post-code-suggestion">
        <p className="mb-0"> {address}</p>
      </div>
    )
  };

  const onChangeLocation = (event, { newValue }) => {
    //console.log("event ==>", event.target.value)
    setLocation(newValue);
    if(newValue.length <= 10){
      props.selectHandelChange('postcode', newValue)
    }
    //props.selectHandelChange('postcode', newValue)
  };

  const getSuggestionSelected = (event, { suggestion }) => {
    props.postcodeChange(suggestion);
  };

  const resultFormation = (suggestion) => {
    var split_address = (suggestion.trim(",")).split(",");
    var address_bind =[];
    split_address.length > 0 && split_address?.map((item) =>{
      if(item.trim()){
        address_bind.push(item)
      }
    });
    var address = address_bind.join(", ");

    //console.log("address_bind", split_address, address_bind)
    // var address = `${suggestion.line_1}`;
    // if (suggestion.line_2) {
    //   address += ` ${suggestion.line_2}`;
    // }
    // if (suggestion.line_3) {
    //   address += `, ${suggestion.line_3}`;
    // }
    // if (suggestion.post_town) {
    //   address += `, ${_.capitalize(suggestion.post_town)}`;
    // }
    // if (suggestion.postal_county) {
    //   address += `, ${_.capitalize(suggestion.postal_county)}`;
    // }
    return address;
  }

  const getSuggestionValue = suggestion => {
    var address = resultFormation(suggestion);
    return address;
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    // 'EC1A 2BN'
    // ApiRequest({
    //   method: "GET",
    //   url: 'https://leadpro-backend-production.herokuapp.com/ivt/addresses',
    //   header: { Authorization: process.env.GATSBY_LEADPRO_APP_ID },
    //   body: { postcode: value }
    // }, (result) => {
    //   setSuggestions(result.data)
    // })

    // var headers =  { Authorization: process.env.GATSBY_LEADPRO_APP_ID }
    // axios.get('https://leadpro-backend-production.herokuapp.com/ivt/addresses', {
    //   headers: headers,
    //   params: { postcode: value }
    // })
    //var headers =  { Authorization: process.env.GATSBY_LEADPRO_APP_ID }
    axios.get(`https://api.getaddress.io/find/${value}/?api-key=cvYkaniFpUm26jZfjphfUw37545`)
    .then(function (response) {
      //console.log("response success 1 =>", response);
      if (response.status === 200) {
        //cb(response.data);
        //console.log("response success 1 =>", response);
        props.setPostCodeError(false);
        //setSuggestions(response.data.data)
        setSuggestions(response?.data?.addresses)
        //console.log("response success =>", response);
      }
    })
    .catch(function (error) {
      //console.log("response error =>", error);
      props.setPostCodeError(true);
      //console.log("response error =>", error);
    })

  };

  const inputProps = {
    value: location,
    onChange: onChangeLocation,
    type: "search",
    placeholder: props.placeholder,
    className: "form-control",
    required: true,

  };

  const { inlineIcon = '', className, label, startDate, setStartDate, fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, grpmd, step, inputlabel, max, maxlength, divclass, errorMsg, startTime, setStartTime } = props;

  return (
    <div
      className={`postcode-field-wrapper  ${className || ''}`}
    >
      {label
        ? <Form.Label crequiredlassName="form-label">{label}{required ? ' *' : ''}</Form.Label>
        : ''
      }
      <Form.Group as={Col} md={grpmd} className={`${type} ${step} ${divclass}`} controlId={"validation" + name}>
        <div className="custom-float">
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={getSuggestionSelected}
            inputProps={inputProps}

          />
        </div>
      </Form.Group>


    </div>
  )
}

export default PostcodeField
